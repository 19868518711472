body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 12pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:lang(he), :lang(arc) {
  font-family: "David", "Taamey Frank CLM", "Frank Ruehl CLM", "Ezra SIL", serif;
  font-size: 14pt;
  direction: rtl;
  text-align: right;
}

:lang(en) {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;font-size: 14pt;
  direction: ltr;
  text-align: left;
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #dddddd;
  z-index: 100;
}

.Header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.Header li {
  float: left;
}

.Header li {
  text-align: center;
  padding: 9px 16px;
}

.Header a {
  display: block;
  color: white;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.Header li a:hover {
  background-color: #111;
}

.Content {
  display: inline-block;
  width: 50%;
  overflow-y: auto;
}

.LeftSidebar {
  display: inline-block;
  width: 22%;
  margin-left: 1%;
  margin-right: 1%;
  position: fixed;
  overflow-y: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  padding-top: 5px;
}

.RightSidebar {
  display: inline-block;
  margin-right: 1%;
  width: 22%;
  position: fixed;
  margin-left: 76%;
  overflow-y: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  padding-top: 5px;
}

.Expandable {
  text-align: left;
  float:left;
  clear:both;
  position: relative;
  margin-left: -45%;
  width:45%;
}

.ExpandableContent {
  width:30%;
  z-index:1;
}

.j\:links, .j\:settings {
  display: none;
}

.tei\:head {
  display: block;
  font-size: 125%;
}

/* turn off verse numbers... not sure we always want it */
.tei\:label {
  display: none;
}

.tei\:lg {
  display: block;
  margin-top: 1ex;
}

.tei\:l {
  display:block;
  margin-top: 0.5ex;
  margin-bottom: 0.5ex;
}

.tei\:p {
  display: block;
  margin-top: 1ex;
  margin-bottom: 1ex;
}